// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user")
    try{
      const user= JSON.parse(loggedInUser)
      const account= user ? user.account:{}
     if(user){
        setIsAuthenticated(true)
        localStorage.setItem("accountType",account.type)
        localStorage.setItem("accountId",account.id)
     }else{
        setIsAuthenticated(false)
     }
    }catch(e){
        setIsAuthenticated(false)
    }
  }, [])

  const logout = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("token")
    localStorage.removeItem("accountType")
    localStorage.removeItem("accountId")
    localStorage.clear()
    setIsAuthenticated(false)
    window.location.reload(false)
  }

  return (
    <AuthContext.Provider 
      value={{ isAuthenticated, setIsAuthenticated, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
